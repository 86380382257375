import React from "react";
import "./banner.scss";
import waveTopImg from "../../images/curves-top.svg";
import waveTopImgMobile from "../../images/curves-top-m.svg";
import waveBottomImg from "../../images/curve-bottom.png";
import waveBottomImgMobile from "../../images/curve-bottom-m.png";
import downArrow from "../../images/icons/down-arrow-icon.svg";

import useDeviceSize from "../../hooks/use-device-size";

let tagText = "Actor portrayal.";

function Banner(props) {
  const deviceSizes = useDeviceSize();
  return (
    <div className={`banner bg-cover ${props.extraClass}`}>
      {deviceSizes?.xsDown ? (
        <img src={props.bannerImgMobile} alt={props.bannerImgAlt} />
      ) : (
        <img src={props.bannerImg} alt={props.bannerImgAlt} />
      )}
      <div className="top-wave">
        <img
          src={props.topWaveDesktop ?? waveTopImg}
          className="for-desktop"
          alt="Wave shape"
        />
        <img
          src={props.topWaveMobile ?? waveTopImgMobile}
          className="for-mobile"
          alt="Wave shape"
        />
      </div>
      <div className="bottom-wave">
        <img
          src={props.bottomWaveDesktop ?? waveBottomImg}
          className="for-desktop"
          alt="Wave shape"
        />
        <img
          src={props.bottomWavemobile ?? waveBottomImgMobile}
          className="for-mobile"
          alt="Wave shape"
        />
      </div>
      <div className="centered-content">
        <div className="banner-content text-white">
          {props.bannerTitle && (
            // <h1>{props.bannerTitle}</h1>
            <h1 dangerouslySetInnerHTML={{ __html: props.bannerTitle }} />
          )}
          {props.bannerDesc && (
            <div dangerouslySetInnerHTML={{ __html: props.bannerDesc }}></div>
          )}
          <div className="btn-wrap">
            <a href={props.nextSecId} className="icon">
              <img src={downArrow} alt="Down Arrow Icon" />
            </a>
          </div>
        </div>
        <div className="tag-text">
          <span> {props.tagText ?? tagText}</span>
          {/*  <span>Actor portrayals.</span> */}
        </div>
      </div>
    </div>
  );
}

export default Banner;
